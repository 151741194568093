<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("loanCurrency.loanType")
                    }}</label>
                    <select
                        ref="loan_type_id"
                        v-model="model.loan_type_id"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('loan_type_id') }"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t("select") }}</option
                        >
                        <option
                            v-for="(loan_type, index) in loanTypes"
                            :key="index"
                            :value="loan_type.loan_type_id"
                            >{{ loan_type.loan_type_en }}</option
                        >
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('loan_type_id')"
                    >
                        {{ errors.first("loan_type_id") }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("loanCurrency.currency")
                    }}</label>
                    <select
                        v-model="model.currency_id"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('currency_id') }"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t("select") }}</option
                        >
                        <option
                            v-for="(c, index) in currencies"
                            :key="index"
                            :value="c.currency_id"
                            >{{ c.currency_name_en }}</option
                        >
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('currency_id')"
                    >
                        {{ errors.first("currency_id") }}
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("loanCurrency.minSize")
                    }}</label>
                    <Poptip trigger="focus">
                        <Input
                            v-model.number="model.min_size"
                            :placeholder="$t('loanCurrency.enterNumber')"
                            style="width: 113%"
                            :class="{
                                'ivu-form-item-error': errors.has('min_size')
                            }"
                        >
                            <i
                                class="fas fa-dollar-sign"
                                slot="prefix"
                                v-if="model.currency_id == 1"
                            ></i>
                            <i
                                class="far fa-registered"
                                slot="prefix"
                                v-if="model.currency_id == 2"
                            ></i>
                            <i
                                class="fas fa-bold"
                                slot="prefix"
                                v-if="model.currency_id == 3"
                            ></i>
                        </Input>
                        <div slot="content">
                            {{
                                model.min_size
                                    ? formatNumber(
                                          model.min_size,
                                          model.currency_id
                                      )
                                    : $t("loanCurrency.enterNumber")
                            }}
                        </div>
                        <div
                            class="ivu-form-item-error-tip tw-text-xs tw-pb-4"
                            v-if="errors.has('min_size')"
                        >
                            {{ errors.first("min_size") }}
                        </div>
                    </Poptip>
                </div>
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("loanCurrency.maxSize")
                    }}</label>
                    <Poptip trigger="focus">
                        <Input
                            v-model.number="model.max_size"
                            :placeholder="$t('loanCurrency.enterNumber')"
                            style="width: 113%"
                            :class="{
                                'ivu-form-item-error': errors.has('max_size')
                            }"
                        >
                            <i
                                class="fas fa-dollar-sign"
                                slot="prefix"
                                v-if="model.currency_id == 1"
                            ></i>
                            <i
                                class="far fa-registered"
                                slot="prefix"
                                v-if="model.currency_id == 2"
                            ></i>
                            <i
                                class="fas fa-bold"
                                slot="prefix"
                                v-if="model.currency_id == 3"
                            ></i>
                        </Input>
                        <div slot="content">
                            {{
                                model.max_size
                                    ? formatNumber(
                                          model.max_size,
                                          model.currency_id
                                      )
                                    : $t("loanCurrency.enterNumber")
                            }}
                        </div>
                        <div
                            class="ivu-form-item-error-tip tw-text-xs tw-pb-4"
                            v-if="errors.has('max_size')"
                        >
                            {{ errors.first("max_size") }}
                        </div>
                    </Poptip>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("loanCurrency.minRate")
                    }}</label>
                    <InputNumber
                        :max="100"
                        v-model="model.min_rate"
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        style="width: 100%"
                    ></InputNumber>
                </div>
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("loanCurrency.maxRate")
                    }}</label>
                    <InputNumber
                        :max="100"
                        v-model="model.max_rate"
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        style="width: 100%"
                    ></InputNumber>
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "loanCurrencyForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                loan_type_id: null,
                currency_id: 1,
                min_size: null,
                max_size: null,
                min_rate: null,
                max_rate: null,
                is_active: true
            }
        };
    },
    computed: {
        ...mapState("creditOperation/loanCurrency", [
            "edit_data",
            "loanTypes",
            "currencies"
        ]),
        ...mapGetters(["formatNumber"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        ...mapActions("creditOperation/loanCurrency", [
            "getLoanType",
            "getCurrency"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getLoanType();
            await this.getCurrency();
            this.setEditData();
            this.loading = false;
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("creditOperation/loanCurrency/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanCurrency/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanCurrency/update", {
                    id: this.edit_data.loan_type_cur_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.loan_type_id.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.loan_type_id = this.edit_data.loan_type_id;
                this.model.currency_id = this.edit_data.currency_id;
                this.model.min_size = this.edit_data.min_size;
                this.model.max_size = this.edit_data.max_size;
                this.model.min_rate = this.edit_data.min_rate;
                this.model.max_rate = this.edit_data.max_rate;
            }
        },
        clearInput() {
            this.model.loan_type_id = null;
            this.model.currency_id = 1;
            this.model.min_size = null;
            this.model.max_size = null;
            this.model.min_rate = null;
            this.model.max_rate = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LAON CURRENCY",
                desc: not.text
            });
        }
    }
};
</script>
