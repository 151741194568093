var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('loanCurrency.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('loanCurrency.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t("addNew")))]),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('loanCurrency.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"loan_type_id",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.loan_type ? row.loan_type.loan_type_en : "")+" ")]}},{key:"currency_id",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.currency ? row.currency.currency_name_en : "")+" ")]}},{key:"min_size",fn:function(ref){
        var row = ref.row;
return [_c('strong',{staticClass:"tw-text-yellow-500"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode(row.min_size, row.currency)))])]}},{key:"max_size",fn:function(ref){
        var row = ref.row;
return [_c('strong',{staticClass:"tw-text-green-500"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode(row.max_size, row.currency)))])]}},{key:"min_rate",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.min_rate)+" % ")]}},{key:"max_rate",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.max_rate)+" % ")]}},{key:"action",fn:function(ref){
        var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-4",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1)]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"title":_vm.$t('loanCurrency.pageTitle')},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('from-action',{ref:"form_action",on:{"cancel":_vm.clearEdit,"fetchData":_vm.fetchData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }